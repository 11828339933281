import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { CommonDropDown } from './CommonDropDown';
import { Error } from './message/Error';
import {
  DriveSystem,
  FreightType, KeyType,
  ParkingBrake,
  ShiftLever,
  TransmissionTypes,
  WeightType, WithOrWithout
} from '../constant/common';
import moment from 'moment';
import { getMinuteList, getTimeList } from '../commonFunctions/datetime';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LockIcon from '@material-ui/icons/LockTwoTone';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const ScheduleEditDialog = ({
  userId, isOpen, title, schedule, facility, setting, rentalReasonMasters,
  facilities, changeSelectedFacility,
  customers, changeSelectedCustomer,
  changeFreeFirstName, changeFreeLastName,
  changeCustomersCar,
  staffs, changeSelectedStaff,
  changeSelectedRentalReason,
  changeStart, changeEnd,
  cancel, addSchedule, saveSchedule, confirmDeleteSchedule,
  changeRequestedCarType,
  changeRequestedWeightType,
  changeRequestedFreightType,
  changeRequestedDriveSystem,
  changeRequestedShiftLever,
  changeRequestedParkingBrake,
  changeRequestedKeyType,
  changeRequestedWithAirConditioner,
  changeMemo,
  changeWarning,
  copySchedule,
  copiedSchedule,
  isLoading, messages
}) => {
  if (!isOpen) { return false; }

  const startDate = schedule.start.format('YYYY-MM-DD');
  const startTime = schedule.start.format('HH:mm');
  const startHour = schedule.start.format('HH');
  const startMinute = schedule.start.format('mm');
  const endDate = schedule.end.format('YYYY-MM-DD');
  const endTime = schedule.end.format('HH:mm');
  const endHour = schedule.end.format('HH');
  const endMinute = schedule.end.format('mm');

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={e => cancel(e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">

        <DialogTitle id="alert-dialog-title" disableTypography={true}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ position: 'relative' }}>

            <Grid container spacing={2} style={{
              opacity: isLoading ? 0.3 : 1,
              height: '100%',
              width: '100%',
              marginTop: 40
            }}>

              <Grid item xs={12}>
                {(addSchedule !== null && copiedSchedule !== null) &&
                <Button color="default" variant="contained"
                  onClick={e => addSchedule({
                    ...copiedSchedule,
                    facilityId: facility.facilityId,
                    facilityName: facility.name,
                    start: schedule.start,
                    end: schedule.end
                  })}>
                  コピーした内容で新規作成
                </Button>
                }
                {saveSchedule !== null &&
                <Button color="default" variant="contained" onClick={e => {
                  copySchedule(schedule);
                  cancel(e);
                }}>
                  スケジュールをコピーする
                </Button>
                }
              </Grid>

              <Grid item xs={12}>
                <CommonDropDown fullWidth={true} labelText="" inputId="select-facility"
                  defaultValue={-1} required={false}
                  list={facilities.map(f => ({ key: f.name, value: f.facilityId }))}
                  value={facility.facilityId}
                  onChange={e => changeSelectedFacility(e.target.value)}/>
              </Grid>

              <Grid item xs={12}>
                <CommonDropDown fullWidth={true} labelText="お客様名" inputId="select-customer"
                  defaultValue={-1} required={false}
                  list={customers.map(c => ({ key: c.name, value: c.customerId }))}
                  value={schedule.customerId}
                  onChange={e => changeSelectedCustomer(e.target.value)}/>
              </Grid>
              <Grid item xs={12}>
                <TextField id="lastName" label="お客様 姓" margin="normal" variant="outlined"
                  value={schedule.freeLastName}
                  onChange={e => changeFreeLastName(e.target.value)}/>
                <TextField id="firstName" label="お客様 名" margin="normal" variant="outlined"
                  value={schedule.freeFirstName}
                  onChange={e => changeFreeFirstName(e.target.value)}/>
              </Grid>
              <Grid item xs={12}>
                <TextField id="customersCar" label="お客様の車種" margin="normal" variant="outlined"
                  value={schedule.customersCar}
                  onChange={e => changeCustomersCar(e.target.value)}
                  fullWidth={true}
                />

              </Grid>

              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography >貸出希望　詳細</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={2} style={{ height: '100%', width: '100%' }}>
                      <Grid item lg={12}>
                        <CommonDropDown
                          showLabel={true} fullWidth={true} defaultValue={-1}
                          list={TransmissionTypes}
                          label="マニュアル/オートマ"
                          inputId={'at_mt_type' + schedule.scheduleId.toString()}
                          value={schedule.requestedCarType}
                          onChange={e => changeRequestedCarType(e.target.value)}/>
                      </Grid>

                      <Grid item lg={3} md={6} sm={12}>
                        <CommonDropDown
                          showLabel={true} fullWidth={true} defaultValue={-1}
                          list={WeightType}
                          label="軽自動車・普通"
                          inputId={'weight-type' + schedule.scheduleId.toString()}
                          value={schedule.requestedWeightType}
                          onChange={e => changeRequestedWeightType(e.target.value)}/>
                      </Grid>

                      <Grid item lg={3} md={6} sm={12}>
                        <CommonDropDown
                          showLabel={true} fullWidth={true} defaultValue={-1}
                          list={FreightType}
                          label="車体の形状"
                          inputId={'freight-type' + schedule.scheduleId.toString()}
                          value={schedule.requestedFreightType}
                          onChange={e => changeRequestedFreightType(e.target.value)}/>
                      </Grid>

                      <Grid item lg={3} md={6} sm={12}>
                        <CommonDropDown
                          showLabel={true} fullWidth={true} defaultValue={-1}
                          list={DriveSystem}
                          label="駆動方式"
                          inputId={'drive_system' + schedule.scheduleId.toString()}
                          value={schedule.requestedDriveSystem}
                          onChange={e => changeRequestedDriveSystem(e.target.value)}/>
                      </Grid>

                      <Grid item lg={3} md={6} sm={12}>
                        <CommonDropDown
                          showLabel={true} fullWidth={true} defaultValue={-1}
                          list={ShiftLever}
                          label="シフトレバー"
                          inputId={'shift_lever' + schedule.scheduleId.toString()}
                          value={schedule.requestedShiftLever}
                          onChange={e => changeRequestedShiftLever(e.target.value)}/>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12}>
                        <CommonDropDown
                          showLabel={true} fullWidth={true} defaultValue={-1}
                          list={ParkingBrake}
                          label="パーキングブレーキ"
                          inputId={'parking_brake' + schedule.scheduleId.toString()}
                          value={schedule.requestedParkingBrake}
                          onChange={e => changeRequestedParkingBrake(e.target.value)}/>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12}>
                        <CommonDropDown
                          showLabel={true} fullWidth={true} defaultValue={-1}
                          list={KeyType}
                          label="キータイプ"
                          inputId={'key_type' + schedule.scheduleId.toString()}
                          value={schedule.requestedKeyType}
                          onChange={e => changeRequestedKeyType(e.target.value)}/>
                      </Grid>

                      <Grid item xs={12}>
                        <CommonDropDown
                          showLabel={true} fullWidth={true} defaultValue={-1}
                          list={WithOrWithout}
                          label="エアコンの有無"
                          inputId={'air_conditioner' + schedule.scheduleId.toString()}
                          value={schedule.requestedWithAirConditioner}
                          onChange={e => changeRequestedWithAirConditioner(e.target.value)}/>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>

              <Grid item xs={12}>
                <CommonDropDown fullWidth={true} label="担当スタッフ" inputId="select-staff"
                  defaultValue={-1} required={true}
                  list={staffs.map(s => ({ key: s.name, value: s.staffId }))}
                  value={schedule.staffId}
                  onChange={e => changeSelectedStaff(e.target.value)}/>
              </Grid>

              <Grid item xs={12}>
                <CommonDropDown fullWidth={true} label="代車貸出理由" inputId="select-staff"
                  defaultValue={-1} required={true}
                  list={rentalReasonMasters
                    .filter(m => !m.isDeleted)
                    .sort((a, b) => a.sortNo - b.sortNo)
                    .map(m => ({ key: m.name, value: m.code }))}
                  value={schedule.rentalReason}
                  onChange={e => changeSelectedRentalReason(e.target.value)}/>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                                    開始
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField id="start" label="日付" type="date" required={true}
                  value={startDate}
                  onKeyDown={e => {
                    if (e.key === 'Backspace') {
                      e.preventDefault();
                    }
                  }}
                  onChange={e => changeStart(moment(e.target.value + 'T' + startTime))}/>

                {setting.dragSnapUnit === 'hour' &&
                <CommonDropDown
                  inputId="select-start_time"
                  label="時刻"
                  defaultValue={'00:00'} required={true}
                  list={getTimeList(setting.dragSnapValue * 60).map(t => ({ key: t, value: t }))}
                  value={startTime}
                  onChange={e => changeStart(moment(startDate + 'T' + e.target.value))}/>
                }

                {setting.dragSnapUnit === 'minute' &&
                <>
                  <CommonDropDown inputId="select-start_hour"
                    label="時"
                    defaultValue={'00'} required={true}
                    list={[
                      '00', '01', '02', '03', '04', '05', '06',
                      '07', '08', '09', '10', '11', '12', '13',
                      '14', '15', '16', '17', '18', '19', '20',
                      '21', '22', '23'
                    ].map(t => ({ key: t, value: t }))}
                    value={startHour}
                    onChange={e => changeStart(moment(startDate + 'T' + e.target.value + ':' + startMinute))}/>

                  <CommonDropDown
                    inputId="select-start_minute"
                    label="分"
                    defaultValue={'00'} required={true}
                    list={getMinuteList(setting.dragSnapValue)
                      .map(t => t.toString().padStart(2, '0'))
                      .map(t => ({ key: t, value: t }))}
                    value={startMinute}
                    onChange={e => changeStart(moment(startDate + 'T' + startHour + ':' + e.target.value))}/>
                </>
                }
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                                    終了
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField id="end" label="日付" type="date" required={true}
                  value={endDate}
                  onKeyDown={e => {
                    if (e.key === 'Backspace') {
                      e.preventDefault();
                    }
                  }}
                  onChange={e => changeEnd(moment(e.target.value + 'T' + endTime))}/>

                {setting.dragSnapUnit === 'hour' &&
                                <CommonDropDown inputId="select-end_time"
                                  label="時刻"
                                  defaultValue={'00:00'} required={true}
                                  list={getTimeList(setting.dragSnapValue * 60).map(t => ({ key: t, value: t }))}
                                  value={endTime}
                                  onChange={e => changeEnd(moment(endDate + 'T' + e.target.value))}/>
                }

                {setting.dragSnapUnit === 'minute' &&
                <>
                  <CommonDropDown
                    inputId="select-end_hour"
                    label="時"
                    defaultValue={'00'} required={true}
                    list={[
                      '00', '01', '02', '03', '04', '05', '06',
                      '07', '08', '09', '10', '11', '12', '13',
                      '14', '15', '16', '17', '18', '19', '20',
                      '21', '22', '23'
                    ].map(t => ({ key: t, value: t }))}
                    value={endHour}
                    onChange={e => changeEnd(moment(endDate + 'T' + e.target.value + ':' + endMinute))}/>

                  <CommonDropDown
                    inputId="select-end_minute"
                    label="分"
                    defaultValue={'00'} required={true}
                    list={getMinuteList(setting.dragSnapValue)
                      .map(t => t.toString().padEnd(2, '0'))
                      .map(t => ({ key: t, value: t }))}
                    value={endMinute}
                    onChange={e => changeEnd(moment(endDate + 'T' + endHour + ':' + e.target.value))}/>
                </>
                }
              </Grid>

              <Grid item xs={12}>
                <TextField id="memo" label="メモ" margin="normal" variant="outlined"
                  multiline fullWidth={true}
                  value={schedule.memo}
                  onChange={e => changeMemo(e.target.value)}/>
              </Grid>
              <Grid item xs={12}>
                <LockIcon style={{ color: 'black' }}/>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={schedule.warning}
                      onChange={changeWarning}
                    />}
                  label="代車変更不可"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          {messages.length > 0 &&
          <div>
            <Error visible={messages.length > 0} message={messages}/>
          </div>
          }

          {confirmDeleteSchedule === null ? false
            : <Button onClick={e => confirmDeleteSchedule(schedule.scheduleId)}
              color="secondary" variant="contained">
                            削除
            </Button>}

          {addSchedule === null
            ? false
            : (
              <Button
                color="primary"
                variant="contained"
                disabled={messages.length > 0}
                onClick={e => addSchedule(schedule)}
              >
              保存
              </Button>
            )
          }

          {saveSchedule === null
            ? false
            : (
              <Button
                color="primary"
                variant="contained"
                disabled={messages.length > 0}
                onClick={e => saveSchedule(schedule)}
              >
                保存
              </Button>)
          }

          <Button onClick={e => cancel(e)} color="default" autoFocus variant="contained">
                        キャンセル
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
};
